const Video = () => {
	return ( 
		<div id="video">
			<iframe title="video"
			src="https://www.youtube.com/embed/kKwfjYwks-g">
			</iframe>
		</div>
	 );
}
 
export default Video;